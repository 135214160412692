.Footer-container{
    position: relative;
}

.Footer-container>hr{
    border:1px solid var(--lightgray)
}

.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-self: center;
    height: 20rem;

}

.social-icon {
    width: 30px; /* Adjust the width to your preference */
    height: auto; /* Maintain the aspect ratio */
    margin-right: 10px; /* Optional margin between icons */
  }
  



.social-links{
    display: flex;
    gap: 1rem;
} 
    
.social-links>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-f>img{
    width: 10rem;
}

.blur-f-1{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter:blur(200px);
    background-color: red;
}
.blur-f-2{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter:blur(200px);
    background-color: rgb(255, 115,0);
}


