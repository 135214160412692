/* Add these styles to your existing CSS or create a new stylesheet */

.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ccc
}


.join-now{
  align-items: first baseline;
  align-items: self-start;
  margin-top: 0%;
}
.signup-form {
  background-color:lightcyan;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 400px;
  text-align: center;
}

h2 {
  color: #333;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 0; /* Remove border-radius to make it squared */
}

.btn {
  padding: 10px 15px;
  margin: 8px 0;
  border: none;
  border-radius: 0;
  cursor: pointer;
  font-size: 16px;
}

.btn-success {
  background-color: #28a745;
  color: #fff;
}

.btn-default {
  background-color: #007bff;
  color: #fff;
}

p {
  margin-top: 15px;
  color: #333;
}

/* Add media queries for responsiveness if needed */
