/* Assuming your payment page container has a class named "payment-container" */
.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

form {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center form contents horizontally */
}

/* Add margin-top to push the image down */
img {
  margin-top: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Additional styles for the "Go Back" button */
button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px; /* Adjust as needed */
}

.dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-top: 100px; */
  background-color: gray;
  padding: 100px;
}